import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row } from "react-grid-system"
import InnerContentWrapper from "../../layout/innerContentContainer"
import FullWidthSection from "../../layout/fullWidthSection"
import loadable from "@loadable/component"
import Column from "../../layout/column"

import LoadingPlaceHolder from "../../loading/loadingPlaceholder"

const LoadableArea = loadable(() => import("./LoadableArea"))

const query = graphql`
  {
    strapiSocialProof {
      headline
      blurb
      logos {
        url
      }
    }
  }
`

const Users = () => {
  const {
    strapiSocialProof: { headline, blurb, logos },
  } = useStaticQuery(query)
  return (
    <FullWidthSection lightGrey>
      <InnerContentWrapper>
        <Container>
          <Row>
            <Column>
              <div style={{ textAlign: "center", width: "100%" }}>
                <h2 style={{ marginBottom: 30 }}>{headline}</h2>
                <p>{blurb}</p>
              </div>
            </Column>
          </Row>
          <LoadableArea fallback={LoadingPlaceHolder} logos={logos} />
        </Container>
      </InnerContentWrapper>
    </FullWidthSection>
  )
}

export default Users
