import React from "react"

import loadable from "@loadable/component"

import LoadingPlaceHolder from "../../loading/loadingPlaceholder"

const LoadableArea = loadable(() => import("./loadableArea"))

const Testimonial = ({ testimonialData }) => (
  <LoadableArea
    fallback={LoadingPlaceHolder}
    testimonialData={testimonialData}
  />
)

export default Testimonial
